import { useState } from "react";
import Button from "../button";
import Modal from "../modal";
import emailjs from 'emailjs-com';

function ModalContact({ setModal, modal, setModalSuccess }) {
    const [phoneValid, setPhoneValid] = useState(false)
    const datePicker = new Date().toISOString().split('T')[0]
    function validatePhone(phone) {
        const phoneReg = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        if (phone.length < 12 && phone[0] !== '8') {
            setPhoneValid(false);
        } if (phone.length < 11 || phone.length > 12) {
            setPhoneValid(false)
        } else {
            if (phoneReg.test(phone)) {
                setPhoneValid(true)
            }
        }
    }

    function formValidation(e) {
        e.preventDefault()
        console.log(phoneValid)
        if (phoneValid) {
            emailjs.sendForm('service_7sjbryk', 'template_c33gw6d', e.target, 'TP8E1ItZ-Rm3eoNHc')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            setModal(false)
            setModalSuccess(true);
            setTimeout(() => {
                setModalSuccess(false)
            }, 2000);
            e.target.reset()
        }
    }
    return (
        <Modal modal={modal} setModal={setModal}>
            <form className='modal__form' onSubmit={(e) => formValidation(e)}>
                <h2 className='modal__subtitle'>Отправьте <span>заявку</span> на посещение нашего комплекса и мы с вами свяжемся</h2>
                <fieldset>
                    <legend>Заполните форму и мы вам перезвоним в ближайшее время</legend>
                    <ul>
                        <li>
                            <input type="text" placeholder="Имя" minLength='2' required name='name' />
                        </li>
                        <li>
                            <input type="tel" placeholder='Номер телефона' minLength='11' maxLength='12' name='phone' required onChange={(e) => validatePhone(e.target.value)} />
                        </li>
                        <li>
                            <legend>Тип отдыха</legend>
                            <select name='type'>
                                <option value='Пейнтбол'>Пейнтбол</option>
                                <option value='Лазертаг'>Лазертаг</option>
                                <option value='Аренда беседок и прогулки на пони'>Аренда беседок и прогулки на пони</option>
                                <option value='Детские развлечения'>Детские развлечения</option>
                                <option value='Детские развлечения'>Праздник под ключ</option>
                            </select>
                        </li>
                        <li>
                            <input type='date' name='date' defaultValue={datePicker} min={datePicker}></input>
                        </li>
                    </ul>
                </fieldset>
                <div>
                    <Button changes='modal__button'>Отправить</Button>
                </div>
            </form>
            <img className='modal__image' src='player-pb.png' alt='image' />
        </Modal>
    )
}

export default ModalContact;