import Button from '../button';
import './index.css'
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import SwiperButtons from '../swiper-buttons';
import ModalImage from '../modal-image';

function About({ setModal }) {
    const [modalImage, setModalImage] = useState(false)
    const [currentImage, setCurrentImage] = useState()
    function renderImage(image) {
        setCurrentImage(image)
        setModalImage(true)
    }
    const items = [
        {
            icon: 'scale.png',
            title: 'Масштаб',
            text: 'У нас огромная игровая территория, огромный выбор развлечений для детей и взрослых, огромная зона отдыха. В нашем клубе можно проводить мероприятия с количеством участников до 200 человек.',
            image: 'scl-about.jpg'
        },
        {
            icon: 'zone.png',
            title: 'Игровая зона',
            text: '25 000 квадратных метров с укрытиями, рвами и мостами. На такой площади одновременно могут сражаться 150 человек и даже больше. И это не банальные укрытия из покрышек или ДСП.',
            image: 'zone-about.jpg'
        },
        {
            icon: 'castle.png',
            title: 'Площадки',
            text: '"Город" - это настоящие жилмассивы из домиков, парков, где есть даже детский сад и площадь Ленина. "Средневековье" - это штурм замка с помощью таранов и катапульт, подвоз обеспечения на телегах и проход через ворота древних стражников. "Техника" - это пожарные машины и база МЧС, автобусы с остановками, заправки и гаражи.',
            image: 'castle-about.jpg'
        },
        {
            icon: 'relax.png',
            title: 'Зона отдыха',
            text: 'Имеются как огромные беседки (вместительностью до 70 человек), так и кафе, со встроенным камином и мангалом. Обширная мангальная зона на улице позволит приготовить блюдо на любой вкус, ведь здесь имеется 5 видов мангалов, печь с казаном и коптильня.',
            image: 'alv-about.jpg'
        },
    ]
    return (
        <section className='about' id='gallery'>
            <div className='about__container'>
                <h2 className='about__title'>О НАШЕМ КЛУБЕ</h2>
                <p className='about__text'>Клуб Вольный Стрелок - это яркая игровая зона, площадью 25 000 квадратных метров. Это возможность поиграть в помещении. Это интереснейшие миссии и сценарии. А также у нас есть шикарная зона отдыха с беседками, мангальной зоной и кафе. Здесь можно отпраздновать день рождения или провести корпоратив для любой компании (даже на 200 человек).</p>
                <Button changes={'about__button'} onOpen={setModal}>Связаться с нами</Button>
            </div>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper" spaceBetween={500}>
                <SwiperSlide><img className='about__image' src='carousel-3.png' alt='slide' /></SwiperSlide>
                <SwiperSlide><img className='about__image' src='carousel-1.png' alt='slide' /></SwiperSlide>
                <SwiperSlide><img className='about__image' src='carousel-2.png' alt='slide' /></SwiperSlide>
                <SwiperSlide><img className='about__image' src='carousel-4.png' alt='slide' /></SwiperSlide>
                <SwiperSlide><img className='about__image' src='carousel-5.png' alt='slide' /></SwiperSlide>
                <SwiperSlide><img className='about__image' src='carousel-7.png' alt='slide' /></SwiperSlide>
                <SwiperButtons />
            </Swiper>
            <div className='about__advantages'>
                <div className='about__advantages-container'>
                    <h2 className='about__advantages-title'>Наши преимущества</h2>
                    <p className='about__advantages-text'>Мы организовываем игры в пейнтбол всех видов уже порядка 20 лет. Тысячи игроков побывали у нас и получили заряд позитива. Вы также можете оказаться в их числе. Что мы предлагаем для жителей Санкт-Петербурга?</p>
                    <div className='about__advantages-items'>
                        {
                            items.map((item, index) => {
                                return (
                                    <div className='about__advantages-item' key={index} onClick={() => renderImage(item.image)}>
                                        <img className='about__advantages-item-image' src={item.icon} alt='icon' />
                                        <div className='about__advantages-item-container'>
                                            <h3 className='about__advantages-item-title'>{item.title}</h3>
                                            <p className='about__more'>Подробнее</p>
                                            <p className='about__advantages-item-text'>{item.text}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <img className='about__advantages-image' src='adv.png' alt='paintball' />
            </div>
            <ModalImage modalImage={modalImage} setModalImage={setModalImage} image={currentImage} setImage={setCurrentImage} />
        </section>
    )
}

export default About;