import './index.css'
import Button from "../button";
import Modal from "../modal";

function ModalDetails({ setModalDetails, modalDetails, setModal, item }) {
    const changes = {
        modal: 'modal-details__container',
        inner: 'modal-details__inner'
    }
    return (
        <Modal modal={modalDetails} setModal={setModalDetails} changes={changes} modalTitle={item && item.title}>
            {item && <div className='modal-details'>
                <img className='modal-details__image' src={item.image} alt='image' />
                <div className='modal-details__container'>
                    <h2 className='modal-details__title'>{item.title}</h2>
                    {item.priceDetails && item.priceDetails.map((text, index) => {
                        return <p className='modal-details__text' key={index}>{text}</p>
                    })}
                    {item.info && item.info.map((text, index) => {
                        return <p className='modal-details__text' key={index}>{text}</p>
                    })}
                    <div className='modal-details__buttons' onClick={() => setModalDetails(false)}>
                        <Button changes='modal-details__button' onOpen={setModal}>Забронировать</Button>
                    </div>
                </div>
            </div>}
        </Modal>
    )
}

export default ModalDetails