import './index.css'

function Button({ children, changes, onOpen }) {
    const handleClick = () => {
        if (typeof onOpen === 'function') {
            onOpen(true);
        }
    }
    return (
        <button className={`button ${changes}`} onClick={handleClick}>{children}</button>
    );
}

export default Button;