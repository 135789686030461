import { useState } from "react";
import Post from "../../components/post";
import { useEffect } from "react";
import $ from 'jquery'
import PostList from "../../components/post-list";
import Header from "../../components/header";
import ModalSuccess from "../../components/modal-success";
import ModalContact from "../../components/modal-contact";
import Button from "../../components/button";
import Contacts from "../../components/contacts";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function News() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [modal, setModal] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)

    const monthSplit = 'янв,фев,мар,апр,мая,июн,июл,авг,сен,окт,ноя,дек'.split(',');

    function setDate(data) {
        const date = new Date(data * 1000);
        const month = monthSplit[date.getMonth()];

        return `${date.getDate()} ${month} ${date.getFullYear()}`;
    }

    useEffect(() => {
        $.ajax({
            url: 'https://api.vk.com/method/wall.get?owner_id=-1526423&v=5.131&access_token=01ebb5c801ebb5c801ebb5c83d02fe6a20001eb01ebb5c864c958429a0fa9519a62ed62&count=20',
            type: 'GET',
            dataType: 'jsonp',
            crossDomain: true,
            success: function (data) {
                console.log(data)
                setIsLoaded(true);
                setItems(data.response.items);
            },
            error: function (err) {
                setIsLoaded(true);
                setError(err);
            }
        })
    }, [])

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className='content-loading'><i className='preloader'></i></div>;
    } else {
        return (
            <div className='page'>
                <Helmet>
                    <title>Новости</title>
                </Helmet>
                <Header setModal={setModal}>
                    <Link to='/' className='header__link'>Главная</Link>
                    <Link to='/paintball' className='header__link'>Пейнтбол</Link>
                    <Link to='/holiday' className='header__link'>Праздник под ключ</Link>
                    <a href='https://vk.com/strelok_club' className='header__link'>Группа VK</a>
                </Header>
                <PostList>
                    {items.map((obj, index) => {
                        if (!obj.copy_history) {
                            return <Post date={setDate(obj.date)} text={obj.text} src={obj.attachments[0]?.photo?.sizes[4].url} key={index} />
                        }
                    })}
                </PostList>
                <Contacts setModal={setModal} />
                <ModalContact setModal={setModal} modal={modal} setModalSuccess={setModalSuccess} />
                <ModalSuccess modalSuccess={modalSuccess} />
            </div>
        )
    }
}

export default News;