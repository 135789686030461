import './index.css'
import parse from 'html-react-parser';

function Post(props) {
    const regex = /\n/g;
    let newText = props.text.split(regex).map((line, index) => (
        <div key={index}>{line}</div>
      ));
    return (
        <a href="https://vk.com/strelok_club">
            <div className='post'>
                <div className='post-header'>
                    <img className='post-avatar' src='https://sun9-67.userapi.com/s/v1/if2/6BZJIgKMIVvz-BOAAwVmER9d20elzwVnrEyPTXyvUoVWUz15xwGo57baDdd_fsFrbEYYLO6fwuE7yJ64WXDfB-pQ.jpg?size=100x100&quality=96&crop=5,29,400,400&ava=1' alt='vk-group.jpg' />
                    <div className='post-container'>
                        <h4 className='post-title'>ВОЛЬНЫЙ СТРЕЛОК - центр пейнтбола СПб</h4>
                        <p className='post-date'>{props.date}</p>
                    </div>
                </div>
                <p className='post-text'>{newText}</p>
                <img className='post-image' src={props.src} alt='' />
            </div>
        </a>
    )
}

export default Post