import Button from '../button';
import Video from '../video';
import './index.css'

function HolidayMain({ setModal }) {
    return (
        <section className='holiday'>
            <Video>
                <div className='holiday__container'>
                    <h1 className='holiday__title'>ВОЛЬНЫЙ СТРЕЛОК ЦЕНТР ПЕЙНТБОЛА СПБ</h1>
                    <Button changes='holiday__button' onOpen={setModal}>Заказать Праздник</Button>
                </div>
            </Video>
        </section>
    );
}

export default HolidayMain;