import { useState } from 'react'
import ModalImage from '../modal-image'
import './index.css'

function Info({ items }) {
    const [modalImage, setModalImage] = useState(false)
    const [currentImage, setCurrentImage] = useState()
    function renderImage(image) {
        setCurrentImage(image)
        setModalImage(true)
    }
    return (
        <section className='info' id='about'>
            <div className='info__container'>
                {
                    items.map((item, index) => {
                        return (
                            <div className='info__item' key={index} onClick={() => renderImage(item.image)}>
                                <img className='info__image' src={item.icon} alt='icon' />
                                <h3 className='info__title'>{item.title}</h3>
                                <p className='info__more'>Подробнее</p>
                                <p className='info__text'>{item.text}</p>
                            </div>
                        )
                    })
                }
            </div>
            <ModalImage modalImage={modalImage} setModalImage={setModalImage} image={currentImage} setImage={setCurrentImage} />
        </section>
    )
}

export default Info;