import Button from '../button';
import './index.css'

function Contacts({ setModal }) {
    return (
        <footer className='footer' id='contacts'>
            <h2 className='footer__title'>КОНТАКТЫ</h2>
            <div className='footer__container'>
                <div className='footer__contacts'>
                    <div className='footer__contacts-item'>
                        <img className='footer__contacts-icon' src='phone.png' alt='' />
                        <p className='footer__contacts-text'>8-921-392-06-20</p>
                    </div>
                    <div className='footer__contacts-item'>
                        <img className='footer__contacts-icon' src='letter.png' alt='' />
                        <p className='footer__contacts-text'>info@v-igre.ru</p>
                    </div>
                    <div className='footer__contacts-item'>
                        <img className='footer__contacts-icon' src='mark.png' alt='' />
                        <p className='footer__contacts-text'>Санкт-Петербург, Заневское городское поселение, Дельтовский проезд, 5616</p>
                    </div>
                    <div className='footer__links'>
                        <a href='https://pro-shar.ru/'>
                            <img className='footer__link' src='proshar.png' alt='link' />
                        </a>
                        <a href='https://rfpaintball.ru/'>
                            <img className='footer__link' src='rfp.png' alt='link' />
                        </a>
                        <a href='https://vk.com/strelok_club'>
                            <img className='footer__link' src='vk.png' alt='link' />
                        </a>
                        <a href='https://t.me/mp_pb'>
                            <img className='footer__link' src='tg.png' alt='link' />
                        </a>
                    </div>
                    <Button changes={'footer__button'} onOpen={setModal}>Оставить заявку</Button>
                </div>
                <iframe className='footer__map' src="https://yandex.ru/map-widget/v1/?um=constructor%3A3eb9c29693e9c0f42a1e0a82fe461a3c9f32c71441b9fa6ab10d746feda56f9a&amp;source=constructor" width="1000" height="500" frameBorder="0"></iframe>
            </div>
            <div className='footer__bottom'>
                <p className='footer__bottom-text'>© Все права защищены 2023 Политика конциденциальности</p>
            </div>
        </footer>
    )
}

export default Contacts;