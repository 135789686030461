import { useState } from "react";
import About from "../../components/about";
import Contacts from "../../components/contacts";
import Header from "../../components/header";
import Info from "../../components/info";
import Modal from "../../components/modal";
import PaintballMain from "../../components/paintball-main";
import Services from "../../components/services";
import Squares from "../../components/squares";
import ModalSuccess from "../../components/modal-success";
import { Helmet } from "react-helmet";
import ModalContact from "../../components/modal-contact";
import { Link } from "react-router-dom";

function Paintball() {
  const [modal, setModal] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const itemsInfoPaintball = [
    {
      icon: 'pb.png',
      title: 'Пейнтбол',
      text: 'Вооружившись маркером и стратегией, вы окажетесь в центре экстремальных битв, где командная тактика и ловкость станут вашими ключевыми союзниками. Опыт истинной схватки, испытайте аутентичное военное действие в безопасной и увлекательной среде.',
      image: 'pb-info.jpg'
    },
    {
      icon: 'laser.png',
      title: 'Лазертаг',
      text: 'Откройте для себя захватывающее приключение, используя передовую технологию лазерных маркеров, чтобы сразиться с друзьями в симулированных битвах. Безопасно и увлекательно, лазертаг объединяет тактику и стратегию в захватывающем опыте, который оставит вас в восторге от адреналиновых приключений будущего!',
      image: 'lt-info.jpeg'
    },
    {
      icon: 'horse.png',
      title: 'Прогулка на пони',
      text: 'Позвольте себе насладиться живописными пейзажами, катаясь на наших дружелюбных пони. Это уникальная возможность для детей и взрослых познакомиться с лошадьми, научиться управлять ими и создать неповторимые воспоминания с этими удивительными животными.',
      image: 'horse-info.jpg'
    },
    {
      icon: 'pg.png',
      title: 'Детские развлечения',
      text: 'Организуйте для своих малышей незабываемые дни радости и веселья с нашими детскими развлечениями! Мы предлагаем безграничные возможности для творчества и игр, чтобы дети могли раскрыть свой потенциал и с удовольствием провести время. У нас есть всё, от интерактивных занятий до веселых мероприятий, чтобы ваши дети улетали в мир фантазии и приключений.',
      image: 'quest-info.png'
    },
  ]

  const itemsSquares = [
    {
      title: 'Замок',
      square: '2 600',
      text: 'Открытая площадка для схватки настоящих рыцарей пейнтбола. Вас ожидает осада настоящего замка и динамичная схватка среди десятков стилизованных построек, тюков с сеном и других укрытий, позволяющих продемонстрировать мастерство тактического боя.',
      image: 'castle-square.jpg',
      order: '1'
    },
    {
      title: 'Деревня',
      square: '6 300',
      text: 'Открытая площадка с множеством построек. Она превращает игру в пейнтбол в настоящий штурм деревеньки, где соперники подстерегают вас в окошках домиков и внезапно обстреливают, укрывшись за поленницей. Готовьтесь к тяжелому, но увлекательному бою.',
      image: 'village-square.jpg',
      order: '2'
    },
    {
      title: 'Автопарк',
      square: '4 550',
      text: 'Площадка для пейнтбола с говорящим названием. Отличный выбор для корпоратива или вечеринки, где в удобной обстановке можно разработать план сражения, провести инструктаж, а потом и отметить победу.',
      image: 'cars-square.jpg',
      order: '1'
    },
    {
      title: 'Спортивная',
      text: 'На территории парка расположены летняя и зимняя (купол) игровые площадки. Наш купол — крупнейшая на сегодняшний день крытая спортивная площадка в Санкт-Петербурге и Москве. Здесь проходят как развлекательные игры, так и регулярные тренировки и соревнования спортивных команд, включая официальные соревнования Российской Федерации пейнтбола.',
      image: 'sport-square.jpg',
      order: '2'
    },
  ]

  const itemsServices = [
    {
      image: 'pb-image.png',
      title: 'Пейнтбол',
      price: '2000',
      priceDetails: [
        'Стоимость: 2000 руб с человека (маска, маркер, форма, 500 шариков, работа инструктора).',
        'Доп. шарики 500 шт = 1000 руб.',
        'Дымы = 500 руб.',
        'Гранаты = 500 руб.'
      ],
      info: [
        'Для компании от 12 человек мы можем организовать настоящий пейнтбольный турнир, победители и призёры которого получат медали и кубки. Стоимость: 3000 руб. с человека.',
        'Пейнтбол на пистолетах – облегчённый вариант пейнтбола, в который могут играть даже дети: Стоимость: 2000 руб с человека за 2 часа игры.'
      ]
    },
    {
      image: 'kritpb.jpg',
      title: 'Крытый пейнтбол',
      price: '1000',
      priceDetails: [
        'Стоимость: 1000 руб с человека.',
      ],
      info: [
        'Ребол - это игра в пейнтбол в крытом помещении, в которой игроки используют специальные шары - реболы.',
        'Специальный тренировочный шар - ребол "reuse" (повторно использовать) и "ball" (шар) изготовлен из мягкой резины и предназначен для многократного использования.', 
        'Скорость маркеров занижена, доступно даже детям!'
      ]
    },
    {
      image: 'lt-image.png',
      title: 'Лазертаг',
      price: 'от 1000',
      priceDetails: [
        '1000₽ - на буднях 2 часа',
        '1300₽ - на выходных 2 часа'
      ],
      info: [
        'Лазертаг – это способ провести время активно, весело и необычно. Такое развлечение подойдёт как для взрослых, так и для детей, так как это игра с использованием безопасного лазерного оружия и сенсоров.'
      ]
    },
    {
      image: 'pb-child.jpg',
      title: 'Детский пейнтбол',
      price: 'от 1000',
      priceDetails: [
        'Стоимость: от 1000₽ за час/безлимит по шарам'
      ],
      info: [
        'Рады вам сообщить о том что в нашем клубе появились маркера для самых маленьких любителей пострелушек',
        'Теперь дети от 6 лет спокойно могут играть в пейнтбол, а их родители не будут переживать за попадания',
      ]
    },
    {
      image: 'pav-image.png',
      title: 'Аренда беседок',
      price: 'от 2000',
      priceDetails: [
        'Аренда беседок определяется индивидуально. Начиная от 200 рублей с человека (зависит от вместимости, количества человек и предлагаемых аксессуаров). Возможна организация полноценной кухни (ассортимент и меню выбираются клиентом).'
      ],
      info: [
        'На выбор мы предлагаем три летние беседки, а также два зимних утеплённых домика для большой (до 40 человек) компании. Ведь можно жарить мясо и зимой, укрывшись от холода и непогоды в этих уютных тёплых домиках.',

      ]
    },
    {
      image: 'pg-image.png',
      title: 'Детские развлечения',
      price: 'от 2000',
      priceDetails: [
        'Стоимость: от 500 руб.'
      ],
      info: [
        'Лазертаг - 2000 руб',
        'Пейнтбол на пистолетах - 2000 руб.',
        'Квесты - узнавайте у организаторов',
        'Катание на пони - от 500 руб'
      ]
    },
    {
      image: 'horse-image.png',
      title: 'Прогулка на пони',
      price: 'от 500',
      priceDetails: [
        'Стоимость: 500-3000 руб.'
      ],
      info: [
        'Наш клуб рад предложить вам прогулки на милейших пони. Дети смогут почувствовать себя маленькими всадниками. Само собой все безопасно. Прогулка будет проходит с опытным инструктором. Дети могут прокатиться как просто по маршруту на территории нашего клуба, так и покататься 30 или 60 минут.'
      ]
    },
    {
      image: 'hol-image.png',
      title: 'Праздник под ключ',
      price: '4000',
      priceDetails: [
        'Стоимость: 4000 руб.'
      ],
      info: [
        'В стоимость входит: пейнтбол/лазертаг, аренда беседки, обед (плов, салат, напитки), футбол/волейбол, катание на пони, машинки, корнхол'
      ]
    },
  ]


  return (
    <div className='page'>
      <Helmet>
        <title>Активный отдых</title>
      </Helmet>
      <Header setModal={setModal}>
        <Link to='/' className='header__link'>Главная</Link>
        <a href='#about' className='header__link'>О нас</a>
        <a href='#gallery' className='header__link'>Галерея</a>
        <a href='#services' className='header__link'>Услуги и цены</a>
        <a href='#contacts' className='header__link'>Контакты</a>
        <Link to='/news' className='header__link'>Новости</Link>
      </Header>
      <PaintballMain setModal={setModal} />
      <Info items={itemsInfoPaintball} />
      <About setModal={setModal} />
      <Services setModal={setModal} items={itemsServices} />
      <Squares setModal={setModal} items={itemsSquares} title='НАШИ ПЛОЩАДКИ' />
      <Contacts setModal={setModal} />
      <ModalContact setModal={setModal} modal={modal} setModalSuccess={setModalSuccess} />
      <ModalSuccess modalSuccess={modalSuccess} />
    </div>
  );
}

export default Paintball;