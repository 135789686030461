import { Helmet } from "react-helmet";
import Main from "../../components/main";

function Home() {
  return (
    <div className='page'>
      <Helmet>
        <title>Вольный стрелок</title>
      </Helmet>
      <Main />
    </div>
  );
}

export default Home;