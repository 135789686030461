import { useSwiper } from "swiper/react";
import './index.css'

const SwiperButtons = () => {
  const swiper = useSwiper();
  return (
    <div className='swiper-buttons'>
      <img onClick={() => swiper.slidePrev()} className='swiper-custom' src='arrow-left.png' />
      <img onClick={() => swiper.slideNext()} className='swiper-custom' src='arrow-right.png'/>
    </div>
  )
};

export default SwiperButtons;