import Button from '../button';
import './index.css'

function PostList({ posts, children }) {
    return (
        <section className='posts'>
            <div className='posts__container'>
                {children}
                <a href='https://vk.com/strelok_club'><Button changes='posts__button'>Больше новостей в нашей группе</Button></a>
            </div>
        </section>
    )
}

export default PostList;