import { useState } from 'react';
import Button from '../button';
import ModalDetails from '../modal-details';
import './index.css'

function Services({ setModal, items }) {
    const [modalDetails, setModalDetails] = useState(false)
    const [currentService, setCurrentService] = useState()
    function renderModal(item) {
        setCurrentService(item)
        setModalDetails(true)
    }
    return (
        <section className='services' id='services'>
            <h2 className='services__title'>УСЛУГИ И ЦЕНЫ</h2>
            <div className='services__container'>
                {
                    items.map((item, index) => {
                        return (
                            <div className='services__item' key={index} onClick={() => renderModal(item)}>
                                <img className='services__image' src={item.image} alt='Изображение' />
                                <div className='services__item-container'>
                                    <h3 className='services__item-title'>{item.title}</h3>
                                    <p className='services__item-price'>{item.price} руб</p>
                                    <Button changes='services__item-button'>Подробнее</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <ModalDetails modalDetails={modalDetails} setModalDetails={setModalDetails} setModal={setModal} item={currentService} />
        </section>
    )
}

export default Services;