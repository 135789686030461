import './index.css'

function ModalImage({ image, modalImage, setModalImage, setImage }) {
    function close() {
        setModalImage(false)
        setImage('')
    }
    return (
        <div className={modalImage ? 'modal-image active' : 'modal-image'} onClick={() => close()}>
            <img className={modalImage ? 'modal-image__image active' : 'modal-image__image'} src={image} alt='image' onClick={(e) => e.stopPropagation()} />
        </div>
    )
}

export default ModalImage;