import { Link } from 'react-router-dom';
import Button from '../button';
import './index.css'
import Video from '../video';

function Main() {
    return (
        <section className='main'>
            <Video>
                <div className='main__container'>
                    <img className='main__logo' src='Logo.png' alt='logo' />
                    <h1 className='main__title'>ЦЕНТР ПЕЙНТБОЛА СПБ</h1>
                    <p className='main__text'>Узнайте все подробности о нас, и выберите свой идеальный вид отдыха</p>
                    <div className='main__buttons'>
                        <Link to={'/paintball'}><Button changes='main__button'>Играть в пейнтбол</Button></Link>
                        <Link to={'/holiday'}><Button changes='main__button'>Праздник под ключ</Button></Link>
                    </div>
                </div>
            </Video>
        </section>
    );
}

export default Main;