import Button from '../button';
import './index.css'

function Squares({ setModal, items, title }) {
    return (
        <section className='squares'>
            <h2 className='squares__title'>{title}</h2>
            <div className='squares__container'>
                {
                    items.map((item, index) => {
                        return (
                            <div className='squares__item' key={index}>
                                <img className={item.order === '1' ? 'squares__image' : 'squares__image squares__image-order'} src={item.image} />
                                <div className='squares__info'>
                                    <h3 className='squares__item-title'>{item.title}</h3>
                                    {item.square && <p className='squares__item-square'>Площадь: {item.square} м2</p>}
                                    <p className='squares__text'>{item.text}</p>
                                    <Button changes='squares__button' onOpen={setModal}>Забронировать</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Squares;