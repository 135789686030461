import './index.css'

function ModalSuccess({ modalSuccess }) {
    return (
        <div className={modalSuccess ? 'modal-success active' : 'modal-success'}>
            <p className='modal-success-title'>Заявка отправлена!</p>
        </div>
    )
}

export default ModalSuccess;