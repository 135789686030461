import Button from "../button";
import Video from "../video";
import Header from "../header";
import './index.css'

function PaintballMain({ setModal }) {
    return (
        <section className='paintball'>
            <Video>
                <div className='paintball__container'>
                    <h2 className='paintball__title'>ВОЛЬНЫЙ СТРЕЛОК</h2>
                    <h1 className='paintball__title'>ЦЕНТР ПЕЙНТБОЛА СПБ</h1>
                    <Button changes='paintball__button' onOpen={setModal}>Заказать игру</Button>
                </div>
            </Video>
        </section>
    );
}

export default PaintballMain;