import { useState } from "react";
import About from "../../components/about";
import Contacts from "../../components/contacts";
import Header from "../../components/header";
import HolidayMain from "../../components/holiday-main";
import Info from "../../components/info";
import Modal from "../../components/modal";
import Services from "../../components/services";
import Squares from "../../components/squares";
import { Helmet } from "react-helmet";
import ModalContact from "../../components/modal-contact";
import ModalSuccess from "../../components/modal-success";
import { Link } from "react-router-dom";

function Holiday() {
  const [modal, setModal] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const itemsInfoHoliday = [
    {
      icon: 'pb.png',
      title: 'Пейнтбол',
      text: 'Вооружившись маркером и стратегией, вы окажетесь в центре экстремальных битв, где командная тактика и ловкость станут вашими ключевыми союзниками. Опыт истинной схватки, испытайте аутентичное военное действие в безопасной и увлекательной среде.',
      image: 'pb-info.jpg'
    },
    {
      icon: 'laser.png',
      title: 'Лазертаг',
      text: 'Откройте для себя захватывающее приключение, используя передовую технологию лазерных маркеров, чтобы сразиться с друзьями в симулированных битвах. Безопасно и увлекательно, лазертаг объединяет тактику и стратегию в захватывающем опыте, который оставит вас в восторге от адреналиновых приключений будущего!',
      image: 'lt-info.jpeg'
    },
    {
      icon: 'horse.png',
      title: 'Прогулка на пони',
      text: 'Позвольте себе насладиться живописными пейзажами, катаясь на наших дружелюбных пони. Это уникальная возможность для детей и взрослых познакомиться с лошадьми, научиться управлять ими и создать неповторимые воспоминания с этими удивительными животными.',
      image: 'horse-info.jpg'
    },
    {
      icon: 'pg.png',
      title: 'Детские развлечения',
      text: 'Организуйте для своих малышей незабываемые дни радости и веселья с нашими детскими развлечениями! Мы предлагаем безграничные возможности для творчества и игр, чтобы дети могли раскрыть свой потенциал и с удовольствием провести время. У нас есть всё, от интерактивных занятий до веселых мероприятий, чтобы ваши дети улетали в мир фантазии и приключений.',
      image: 'quest-info.png'
    },
    {
      icon: 'alcove-icon.png',
      title: 'Аренда беседки',
      text: 'Наши красивые беседки на природе предоставляют уютное и комфортное пространство для ваших мероприятий под открытым небом. С нами вы получите возможность насладиться природой, собраться с семьей и друзьями или провести деловую встречу в уникальной обстановке. Разнообразие размеров и дизайнов беседок позволит вам выбрать идеальную опцию для вашего случая.',
      image: 'alc-info.jpg'
    },
    {
      icon: 'cornhole.png',
      title: 'КорнХолл',
      text: 'Откройте для себя веселую игру, которая объединяет друзей и семью - корнхолл! Эта захватывающая игра с мешками и досками предоставляет непередаваемое удовольствие для всех возрастов. Погрузитесь в атмосферу соревнования и дружелюбного состязания на свежем воздухе. Принесите радость и веселье на вашу вечеринку или мероприятие с корнхоллом',
      image: 'cornhall-info.jpg'
    },
    {
      icon: 'ball.png',
      title: 'Футбол/волейбол',
      text: 'Играйте в футбол и волейбол с нами и ощутите весь волшебный мир спорта! В наших играх каждое касание, каждая передача и каждый мяч - это шаг к победе. У нас вы найдете атмосферу соревнования и дружелюбие команды, где ваши навыки, тактика и сотрудничество приведут к незабываемым моментам.',
      image: 'ball-info.jpg'
    },
    {
      icon: 'car-icon.png',
      title: 'Машинки',
      text: 'Освоите навыки и скорость с нашими машинками на радиоуправлении! Откройте мир захватывающих гонок и приключений, контролируя мощные машинки с легкостью. Наши технологически продвинутые модели обеспечат вам максимальный контроль и удовольствие от игры.',
      image: 'car-info.png'
    },
  ]

  const itemsSquares = [
    {
      title: 'Пейнтбол',
      text: 'Мы – крупнейший на сегодняшний день спортивный комплекс в Санкт-Петербурге и Ленинградской области, предназначенный для проведения игр и тренировок по пейнтболу. Основным преимуществом этого места является то, что состязания могут проводиться здесь круглогодично. Парк предполагает проведения игр различной направленности — и тактического, и спортивного пейнтбола, а также подходит для проведения корпоративных мероприятий и частных праздников.',
      image: 'square-1.jpg',
      order: '1'
    },
    {
      title: 'Лазертаг',
      text: 'В сравнении с другими играми, лазерный пейнтбол является одним из наиболее безопасных. В ходе перестрелок здесь не используются боеприпасы, способные травмировать игрока – бои проходят при помощи лазерного оружия. Каждый участник получает лазерную винтовку, способную стрелять инфракрасными лучами (аналогичные лучи используются телевизионным пультом для переключения каналов). На головах игроков находятся специализированные датчики, сообщающие о поражении. Лазертаг отлично подойдет детям от 5 лет и тем, кто не хочет играть в пейнтбол из соображений безопасности.',
      image: 'lasertag.jpg',
      order: '2'
    },
    {
      title: 'Футбол',
      text: 'Наши гости могут насладиться игрой в футбол на нашей современной футбольной площадке. Независимо от вашего уровня подготовки, у нас есть место для всех. Присоединяйтесь к нам для активного отдыха и зарядки позитивной энергией на футбольном поле!',
      image: 'football.jpg',
      order: '1'
    },
    {
      title: 'Волейбол',
      text: 'На территории нашего комплекса вас ждет увлекательная игра в волейбол на современной площадке. Соберите команду и окуньтесь в мир спортивных соревнований! У нас есть все необходимое для приятной игры и активного времяпровождения на волейбольной площадке. Приходите и побеждайте вместе с нами!',
      image: 'volleyball.jpg',
      order: '2'
    },
    {
      title: 'Беседки',
      text: 'Наши беседки - идеальное место для организации праздников и отдыха на свежем воздухе. Уютные и комфортные, они предоставляют идеальное пространство для семейных встреч, дружеских посиделок и корпоративных мероприятий. Забронируйте беседку сейчас и создайте незабываемые воспоминания с нами!',
      image: 'alcove.jpg',
      order: '1'
    },
    {
      title: 'Машинки',
      text: 'Почувствуйте адреналин и управляйте машинками на радиоуправлении на нашей специально оборудованной площадке. Эта увлекательная услуга подходит для всех возрастов и уровней навыков. Получите удовольствие от гонок и маневров наших машинок в безопасной и контролируемой среде. Приходите и погрузитесь в мир радиоуправляемых приключений!',
      image: 'cars.png',
      order: '2'
    },
    {
      title: 'Корнхол',
      text: 'Cornhole - популярная в Северной Америке игра на лужайке, в которой игроки или команды по очереди бросают тканевые мешки с фасолью на приподнятую под углом доску с отверстием в дальнем конце. Цель игры состоит в том, чтобы набрать очки, либо поставив сумку на доску (одно очко), либо пропустив сумку через отверстие (три очка).',
      image: 'cornhole.jpg',
      order: '1'
    },
    {
      title: 'Прогулка на пони',
      text: 'Ощутите волшебство прогулки на пони в нашем комплексе! Наши дружелюбные пони готовы подарить незабываемый опыт детям и взрослым. Проведите время на свежем воздухе, наслаждаясь природой и компанией этих чудесных животных. Создайте незабываемые моменты с нашими пони!',
      image: 'poni.jpeg',
      order: '2'
    },
  ]

  const itemsServices = [
    {
      image: 'pb-image.png',
      title: 'Пейнтбол',
      price: '2000',
      priceDetails: [
        'Стоимость: 2000 руб с человека (маска, маркер, форма, 500 шариков, работа инструктора).',
        'Доп. шарики 500 шт = 1000 руб.',
        'Дымы = 500 руб.',
        'Гранаты = 500 руб.'
      ],
      info: [
        'Для компании от 12 человек мы можем организовать настоящий пейнтбольный турнир, победители и призёры которого получат медали и кубки. Стоимость: 3000 руб. с человека.',
        'Пейнтбол на пистолетах – облегчённый вариант пейнтбола, в который могут играть даже дети: Стоимость: 2000 руб с человека за 2 часа игры.'
      ]
    },
    {
      image: 'kritpb.jpg',
      title: 'Крытый пейнтбол',
      price: '1000',
      priceDetails: [
        'Стоимость: 1000 руб с человека.',
      ],
      info: [
        'Ребол - это игра в пейнтбол в крытом помещении, в которой игроки используют специальные шары - реболы.',
        'Специальный тренировочный шар - ребол "reuse" (повторно использовать) и "ball" (шар) изготовлен из мягкой резины и предназначен для многократного использования.', 
        'Скорость маркеров занижена, доступно даже детям!'
      ]
    },
    {
      image: 'lt-image.png',
      title: 'Лазертаг',
      price: 'от 1000',
      priceDetails: [
        '1000₽ - на буднях 2 часа',
        '1300₽ - на выходных 2 часа'
      ],
      info: [
        'Лазертаг – это способ провести время активно, весело и необычно. Такое развлечение подойдёт как для взрослых, так и для детей, так как это игра с использованием безопасного лазерного оружия и сенсоров.'
      ]
    },
    {
      image: 'pb-child.jpg',
      title: 'Детский пейнтбол',
      price: 'от 1000',
      priceDetails: [
        'Стоимость: от 1000₽ за час/безлимит по шарам'
      ],
      info: [
        'Рады вам сообщить о том что в нашем клубе появились маркера для самых маленьких любителей пострелушек',
        'Теперь дети от 6 лет спокойно могут играть в пейнтбол, а их родители не будут переживать за попадания',
      ]
    },
    {
      image: 'pav-image.png',
      title: 'Аренда беседок',
      price: 'от 2000',
      priceDetails: [
        'Аренда беседок определяется индивидуально. Начиная от 200 рублей с человека (зависит от вместимости, количества человек и предлагаемых аксессуаров). Возможна организация полноценной кухни (ассортимент и меню выбираются клиентом).'
      ],
      info: [
        'На выбор мы предлагаем три летние беседки, а также два зимних утеплённых домика для большой (до 40 человек) компании. Ведь можно жарить мясо и зимой, укрывшись от холода и непогоды в этих уютных тёплых домиках.',

      ]
    },
    {
      image: 'pg-image.png',
      title: 'Детские развлечения',
      price: 'от 2000',
      priceDetails: [
        'Стоимость: от 500 руб.'
      ],
      info: [
        'Лазертаг - 2000 руб',
        'Пейнтбол на пистолетах - 2000 руб.',
        'Квесты - узнавайте у организаторов',
        'Катание на пони - от 500 руб'
      ]
    },
    {
      image: 'horse-image.png',
      title: 'Прогулка на пони',
      price: 'от 500',
      priceDetails: [
        'Стоимость: 500-3000 руб.'
      ],
      info: [
        'Наш клуб рад предложить вам прогулки на милейших пони. Дети смогут почувствовать себя маленькими всадниками. Само собой все безопасно. Прогулка будет проходит с опытным инструктором. Дети могут прокатиться как просто по маршруту на территории нашего клуба, так и покататься 30 или 60 минут.'
      ]
    },
    {
      image: 'hol-image.png',
      title: 'Праздник под ключ',
      price: '4000 руб.',
      priceDetails: [
        'Стоимость: 4000 руб.'
      ],
      info: [
        'В стоимость входит: пейнтбол/лазертаг, аренда беседки, обед (плов, салат, напитки), футбол/волейбол, катание на пони, машинки, корнхол'
      ]
    }
  ]
  return (
    <div className='page'>
      <Helmet>
        <title>Праздник под ключ</title>
      </Helmet>
      <Header setModal={setModal}>
        <Link to='/' className='header__link'>Главная</Link>
        <a href='#about' className='header__link'>О нас</a>
        <a href='#gallery' className='header__link'>Галерея</a>
        <a href='#services' className='header__link'>Услуги и цены</a>
        <a href='#contacts' className='header__link'>Контакты</a>
        <Link to='/news' className='header__link'>Новости</Link>
      </Header>
      <HolidayMain setModal={setModal} />
      <Info items={itemsInfoHoliday} />
      <About setModal={setModal} />
      <Services setModal={setModal} items={itemsServices} />
      <Squares items={itemsSquares} title='НАШИ АКТИВНОСТИ' setModal={setModal} />
      <Contacts setModal={setModal} />
      <ModalContact setModal={setModal} modal={modal} setModalSuccess={setModalSuccess} />
      <ModalSuccess modalSuccess={modalSuccess} />
    </div>
  );
}

export default Holiday;