import './index.css'

function Modal({ children, modal, setModal, changes, modalTitle }) {
    const handleClick = () => {
        if (typeof setModal === 'function') {
            setModal(false);
        }
    }

    return (
        <div className={modal ? 'modal active' : 'modal'}>
            <div className={modal ? `modal__container active ${changes?.modal}` : `modal__container ${changes?.modal}`}>
                <div className='modal__header'>
                    <h4 className='modal__title'>{modalTitle ? modalTitle : 'Оставить заявку'}</h4>
                    <p className='modal__close' onClick={handleClick}>x</p>
                </div>
                <div className={`modal__inner ${changes?.inner}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;