import { Route, Routes } from "react-router-dom";
import Home from "./home";
import Paintball from "./paintball";
import Holiday from "./holiday";
import News from "./news";

function App() {
  return (
    <Routes>
      <Route path={''} element={<Home />} />
      <Route path={'/paintball'} element={<Paintball />} />
      <Route path={'/holiday'} element={<Holiday />} />
      <Route path={'/news'} element={<News />} />
    </Routes>
  );
}

export default App;
