import { Link } from 'react-router-dom';
import './index.css'
import Button from '../button';
import Modal from '../modal';
import { useState } from 'react';

function Header({ setModal, children }) {
    return (
        <header className='header'>
            <div className='header__logo-container'>
                <img className='header__logo' src='Logo.png' alt='logo' />
            </div>
            <div className='header__container'>
                {children}
            </div>
            <div className='header__buttons'>
                <a className='header__contact' href='https://wa.me/89213920620'>
                    <img className='header__whatsapp' src='whatsapp.png' alt='' />
                    <p className='header__phone'>8-921-392-06-20</p>
                </a>
                <Button changes='header__button header__button-news'><Link className='header__button-text' to='/news'>Новости</Link></Button>
                <Button changes='header__button' onOpen={setModal}>Связаться с нами</Button>
            </div>
        </header>
    );
}

export default Header;