import './index.css'

function Video({ children }) {
    return (
        <div className='video-container'>
            <video className='video' autoPlay muted loop>
                <source src='main-video.mp4' type='video/mp4' />
            </video>
            <div className='video-bg' />
            {children}
        </div>
    );
}

export default Video;